export const MaxImageSizes = {
  Background: {
    width: 1488,
    height: 480,
  },
  EventBackground: {
    width: 720,
    height: 480,
  },
  AvatarAndLogo: {
    width: 256,
    height: 256,
  },
};

export const MinCropSizes = {
  Background: {
    width: 124,
    height: 40,
  },
  EventBackground: {
    width: 60,
    height: 40,
  },
  AvatarAndLogo: {
    width: 100,
    height: 100,
  },
};

export const ImageAspects = {
  Background: 31 / 10,
  EventBackground: 3 / 2,
  AvatarAndLogo: 1,
};

export const DefaultImages = {
  Event: {
    Background: "/images/default-event-background.jpg",
    BlurredBackground: "/images/default-event-background-blurred.jpg",
  },
  Project: {
    Background: "/images/default-project-background.jpg",
    BlurredBackground: "/images/default-project-background-blurred.jpg",
  },
  Organization: {
    Background: "/images/default-organization-background.jpg",
    BlurredBackground: "/images/default-organization-background-blurred.jpg",
  },
  Profile: {
    Background: "/images/default-profile-background.jpg",
    BlurredBackground: "/images/default-profile-background-blurred.jpg",
  },
};
